/* stylelint-disable*/
@charset "UTF-8";
@font-face {
	font-family: 'icomoon';
	src: url('/assets/fonts/icomoon.eot?rfzp4p');
	src: url('/assets/fonts/icomoon.eot?rfzp4p#iefix') format('embedded-opentype'),
		url('/assets/fonts/icomoon.ttf?rfzp4p') format('truetype'),
		url('/assets/fonts/icomoon.woff?rfzp4p') format('woff'),
		url('/assets/fonts/icomoon.svg?rfzp4p#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.link--icon {
	[class^="icon-"]::before,
	[class*="icon-"]::before {
    font-family: 'icomoon' !important;
	}
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 2rem; // icon size (Initial size - don't change)
  color: var(--color-icons);

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-person-juggler:before {
  content: "\ed3d";
}
.icon-people-friends:before {
  content: "\ed41";
}
.icon-location:before {
  content: "\ed44";
}
.icon-map-pin:before {
  content: "\ed45";
}
.icon-one:before {
  content: "\ed4b";
}
.icon-two:before {
  content: "\ed4c";
}
.icon-three:before {
  content: "\ed4d";
}
.icon-four:before {
  content: "\ed4e";
}
.icon-five:before {
  content: "\ed4f";
}
.icon-six:before {
  content: "\ed50";
}
.icon-seven:before {
  content: "\ed51";
}
.icon-eight:before {
  content: "\ed52";
}
.icon-nine:before {
  content: "\ed53";
}
.icon-ten:before {
  content: "\ed54";
}
.icon-eleven:before {
  content: "\ed55";
}
.icon-twelve:before {
  content: "\ed56";
}
.icon-thirteen:before {
  content: "\ed57";
}
.icon-fourteen:before {
  content: "\ed58";
}
.icon-fifteen:before {
  content: "\ed59";
}
.icon-sixteen:before {
  content: "\ed5a";
}
.icon-seventeen:before {
  content: "\ed5b";
}
.icon-eighteen:before {
  content: "\ed5c";
}
.icon-nineteen:before {
  content: "\ed5d";
}
.icon-twenty:before {
  content: "\ed5e";
}
.icon-neighborhood:before {
  content: "\ed5f";
}
.icon-closed-window:before {
  content: "\ed60";
}
.icon-bridge:before {
  content: "\ed61";
}
.icon-basement:before {
  content: "\ed62";
}
.icon-elevator-doors:before {
  content: "\ed63";
}
.icon-floor-plan:before {
  content: "\ed64";
}
.icon-staircase:before {
  content: "\ed65";
}
.icon-balcony:before {
  content: "\ed66";
}
.icon-sink:before {
  content: "\ed67";
}
.icon-shower:before {
  content: "\ed68";
}
.icon-wooden-floor:before {
  content: "\ed69";
}
.icon-new-window-arrow:before {
  content: "\ed3c";
}
.icon-railcar:before {
  content: "\ed0e";
}
.icon-tram:before {
  content: "\ed0f";
}
.icon-doll:before {
  content: "\ed3e";
}
.icon-treehouse:before {
  content: "\ed3f";
}
.icon-romper:before {
  content: "\ed40";
}
.icon-baby-bottle:before {
  content: "\ed42";
}
.icon-baby-mobile:before {
  content: "\ed43";
}
.icon-heart-message:before {
  content: "\ed46";
}
.icon-heart-hands-handmade:before {
  content: "\ed47";
}
.icon-handshake-heart:before {
  content: "\ed48";
}
.icon-rose:before {
  content: "\ed49";
}
.icon-heart-with-arrow:before {
  content: "\ed10";
}
.icon-heart-puzzle:before {
  content: "\ed11";
}
.icon-diamond-ring:before {
  content: "\ed12";
}
.icon-wedding-cake:before {
  content: "\ed13";
}
.icon-hand-holding-heart:before {
  content: "\ed14";
}
.icon-wedding-rings:before {
  content: "\ed15";
}
.icon-mermaid:before {
  content: "\ed4a";
}
.icon-viking-ship:before {
  content: "\ed16";
}
.icon-viking-helmet:before {
  content: "\ed17";
}
.icon-horseshoe:before {
  content: "\ed18";
}
.icon-three-leaf-clover:before {
  content: "\ed19";
}
.icon-egg-easter:before {
  content: "\ed1a";
}
.icon-egg-basket:before {
  content: "\ed1b";
}
.icon-flowers:before {
  content: "\ed1c";
}
.icon-bunny:before {
  content: "\ed1d";
}
.icon-thanksgiving:before {
  content: "\ed1e";
}
.icon-black-cat:before {
  content: "\ed1f";
}
.icon-day-of-the-dead:before {
  content: "\ed20";
}
.icon-bat-flying:before {
  content: "\ed21";
}
.icon-pumpkin:before {
  content: "\ed22";
}
.icon-magic-crystal-ball:before {
  content: "\ed23";
}
.icon-ghost:before {
  content: "\ed24";
}
.icon-eid-mubarak:before {
  content: "\ed25";
}
.icon-menorah:before {
  content: "\ed26";
}
.icon-hanukkah:before {
  content: "\ed27";
}
.icon-snowman:before {
  content: "\ed28";
}
.icon-decorating-christmas-tree:before {
  content: "\ed29";
}
.icon-christmas-tree:before {
  content: "\ed2a";
}
.icon-christmas-stocking:before {
  content: "\ed2b";
}
.icon-candy-cane:before {
  content: "\ed2c";
}
.icon-shooting-star:before {
  content: "\ed2d";
}
.icon-sparkling:before {
  content: "\ed2e";
}
.icon-people-party:before {
  content: "\ed2f";
}
.icon-disco-ball:before {
  content: "\ed30";
}
.icon-firework-rocket:before {
  content: "\ed31";
}
.icon-firework-explosion:before {
  content: "\ed32";
}
.icon-calender-ramadan:before {
  content: "\ed33";
}
.icon-calendar-day-of:before {
  content: "\ed34";
}
.icon-flag-eu:before {
  content: "\ed35";
}
.icon-flag-nordic-cross:before {
  content: "\ed36";
}
.icon-flag-empty:before {
  content: "\ed37";
}
.icon-flag:before {
  content: "\ed38";
}
.icon-circle-half:before {
  content: "\ed39";
}
.icon-circle-grayscale:before {
  content: "\ed3a";
}
.icon-circle-grayscale-full:before {
  content: "\ed3b";
}
.icon-barnets-lov:before {
  content: "\ed0d";
}
.icon-mobile-notification:before {
  content: "\ed08";
}
.icon-eu-citizen:before {
  content: "\ed09";
}
.icon-traffic:before {
  content: "\ed0a";
}
.icon-aarhus-raadhus:before {
  content: "\ed0b";
}
.icon-plus-cross:before {
  content: "\ed0c";
}
.icon-microsoft-excel:before {
  content: "\ec6e";
}
.icon-excel:before {
  content: "\ed07";
}
.icon-download:before {
  content: "\ec4d";
}
.icon-close-cross:before {
  content: "\ec6b";
}
.icon-key-skeleton:before {
  content: "\ec79";
}
.icon-key-skeleton-tilted:before {
  content: "\ec96";
}
.icon-browser-key:before {
  content: "\ecf9";
}
.icon-key:before {
  content: "\ed03";
}
.icon-key-tilted:before {
  content: "\ed04";
}
.icon-padlock:before {
  content: "\ed05";
}
.icon-keyhole-round:before {
  content: "\ed06";
}
.icon-nemid:before {
  content: "\ec41";
}
.icon-face-mask:before {
  content: "\ecbf";
}
.icon-virus-test:before {
  content: "\eccd";
}
.icon-hand-sanitiser:before {
  content: "\ecd1";
}
.icon-hands-wash:before {
  content: "\ecd2";
}
.icon-advertising-campaign:before {
  content: "\ecd3";
}
.icon-polling-place:before {
  content: "\ecda";
}
.icon-campaign-signs:before {
  content: "\ecf2";
}
.icon-protest-signs:before {
  content: "\ecf8";
}
.icon-raised-hand-question:before {
  content: "\ec34";
}
.icon-hands-raised:before {
  content: "\ecfa";
}
.icon-debate-people:before {
  content: "\ecfb";
}
.icon-hand-point-choice:before {
  content: "\ecfc";
}
.icon-voting-ballot:before {
  content: "\ecfd";
}
.icon-voting-hand:before {
  content: "\ecfe";
}
.icon-voting-box:before {
  content: "\ecff";
}
.icon-voting-booth:before {
  content: "\ed00";
}
.icon-pride-flag-hand:before {
  content: "\ed01";
}
.icon-pride-flag:before {
  content: "\ed02";
}
// .icon-frog:before {
//   content: "\ec4d";
// }
// .icon-peacock:before {
//   content: "\ecf9";
// }
// .icon-flying-duck:before {
//   content: "\ec41";
// }
// .icon-fish-clown:before {
//   content: "\ec6e";
// }
// .icon-lamb:before {
//   content: "\ec79";
// }
// .icon-orca:before {
//   content: "\ec96";
// }
.icon-shrimp-lobster:before {
  content: "\ec28";
}
.icon-shrimp:before {
  content: "\ec2d";
}
.icon-lobster:before {
  content: "\ec2e";
}
.icon-seahorse:before {
  content: "\ec2f";
}
.icon-jellyfish:before {
  content: "\ec30";
}
.icon-crab:before {
  content: "\ec31";
}
.icon-starfish:before {
  content: "\ec32";
}
.icon-nautilus:before {
  content: "\ec33";
}
.icon-fish-koi:before {
  content: "\ec35";
}
.icon-fish:before {
  content: "\ec36";
}
.icon-fish-bream:before {
  content: "\ec37";
}
.icon-stingray:before {
  content: "\ec38";
}
.icon-tentacles:before {
  content: "\ec39";
}
.icon-octopus:before {
  content: "\ec3a";
}
.icon-coral:before {
  content: "\ec3b";
}
.icon-whale:before {
  content: "\ec3c";
}
.icon-shark-fin:before {
  content: "\ec3d";
}
.icon-shark:before {
  content: "\ec3e";
}
.icon-dolphin:before {
  content: "\ec3f";
}
.icon-tail-of-whale:before {
  content: "\ec40";
}
.icon-manatee:before {
  content: "\ec42";
}
.icon-seal:before {
  content: "\ec43";
}
.icon-panda:before {
  content: "\ec44";
}
.icon-alligator:before {
  content: "\ec45";
}
.icon-lion:before {
  content: "\ec46";
}
.icon-giraffe:before {
  content: "\ec47";
}
.icon-elephant:before {
  content: "\ec48";
}
.icon-wildlife-animals:before {
  content: "\ec49";
}
.icon-group-og-animals:before {
  content: "\ec4a";
}
.icon-rattlesnake:before {
  content: "\ec4b";
}
.icon-snake:before {
  content: "\ec4c";
}
.icon-frog-face:before {
  content: "\ec4e";
}
.icon-worm:before {
  content: "\ec4f";
}
.icon-snail:before {
  content: "\ec50";
}
.icon-slug-eating:before {
  content: "\ec51";
}
.icon-slug:before {
  content: "\ec52";
}
.icon-caterpillar:before {
  content: "\ec53";
}
.icon-grasshopper:before {
  content: "\ec54";
}
.icon-cockroach:before {
  content: "\ec55";
}
.icon-ant:before {
  content: "\ec56";
}
.icon-flea:before {
  content: "\ec57";
}
.icon-dragonfly:before {
  content: "\ec58";
}
.icon-butterfly-side-view:before {
  content: "\ec59";
}
.icon-butterfly:before {
  content: "\ec5a";
}
.icon-tiger-butterfly:before {
  content: "\ec5b";
}
.icon-artificial-bee-hive:before {
  content: "\ec5c";
}
.icon-honeycombs:before {
  content: "\ec5d";
}
.icon-bee-hive:before {
  content: "\ec5e";
}
.icon-bee-top-view:before {
  content: "\ec5f";
}
.icon-queen-bee:before {
  content: "\ec60";
}
.icon-wasp:before {
  content: "\ec61";
}
.icon-bee:before {
  content: "\ec62";
}
.icon-bee-swarm:before {
  content: "\ec63";
}
.icon-dinosaur:before {
  content: "\ec64";
}
.icon-dragon:before {
  content: "\ec65";
}
.icon-dinosaur-egg:before {
  content: "\ec66";
}
.icon-unicorn:before {
  content: "\ec67";
}
.icon-bird-nest:before {
  content: "\ec68";
}
.icon-bird-house:before {
  content: "\ec69";
}
.icon-owl:before {
  content: "\ec6a";
}
.icon-seagull:before {
  content: "\ec6c";
}
.icon-bird-top-view:before {
  content: "\ec6d";
}
.icon-peacock-head:before {
  content: "\ec6f";
}
.icon-crow:before {
  content: "\ec70";
}
.icon-swan:before {
  content: "\ec71";
}
.icon-dove:before {
  content: "\ec72";
}
.icon-peace-pigeon:before {
  content: "\ec73";
}
.icon-bull:before {
  content: "\ec74";
}
.icon-cow:before {
  content: "\ec75";
}
.icon-horse:before {
  content: "\ec76";
}
.icon-pig:before {
  content: "\ec77";
}
.icon-livestock:before {
  content: "\ec78";
}
.icon-donkey:before {
  content: "\ec7a";
}
.icon-chicken:before {
  content: "\ec7b";
}
.icon-bird-feeder:before {
  content: "\ec7c";
}
.icon-wolf:before {
  content: "\ec7d";
}
.icon-deer-sign:before {
  content: "\ec7e";
}
.icon-deer:before {
  content: "\ec7f";
}
.icon-squirrel:before {
  content: "\ec80";
}
.icon-hedgehog:before {
  content: "\ec81";
}
.icon-rectangular-aquarium:before {
  content: "\ec82";
}
.icon-fish-bowl:before {
  content: "\ec83";
}
.icon-rat:before {
  content: "\ec84";
}
.icon-rabbit:before {
  content: "\ec85";
}
.icon-turtel:before {
  content: "\ec86";
}
.icon-guinea-pig:before {
  content: "\ec87";
}
.icon-hamster-wheel:before {
  content: "\ec88";
}
.icon-cat-caregivers:before {
  content: "\ec89";
}
.icon-Scratching-post:before {
  content: "\ec8a";
}
.icon-cat:before {
  content: "\ec8b";
}
.icon-cat-eyes:before {
  content: "\ec8c";
}
.icon-kitten:before {
  content: "\ec8d";
}
.icon-cat-head:before {
  content: "\ec8e";
}
.icon-puppy:before {
  content: "\ec8f";
}
.icon-angry-dog:before {
  content: "\ec90";
}
.icon-dog-park:before {
  content: "\ec91";
}
.icon-dog-pee:before {
  content: "\ec92";
}
.icon-dog-pooping:before {
  content: "\ec93";
}
.icon-dog-walking:before {
  content: "\ec94";
}
.icon-dog:before {
  content: "\ec95";
}
.icon-dog-bone:before {
  content: "\ec97";
}
.icon-doggy-bag:before {
  content: "\ec98";
}
.icon-dog-collar:before {
  content: "\ec99";
}
.icon-dog-house:before {
  content: "\ec9a";
}
.icon-dog-cat:before {
  content: "\ec9b";
}
.icon-house-cat-dog:before {
  content: "\ec9c";
}
.icon-dog-training:before {
  content: "\ec9d";
}
.icon-pet-doctor:before {
  content: "\ec9e";
}
.icon-veterinary-medicin:before {
  content: "\ec9f";
}
.icon-paw-no:before {
  content: "\eca0";
}
.icon-news-paper:before {
  content: "\eca1";
}
.icon-kayak:before {
  content: "\eca2";
}
.icon-street-light:before {
  content: "\eca3";
}
.icon-vollyball-net:before {
  content: "\eca4";
}
.icon-barbecue:before {
  content: "\eca5";
}
.icon-outdoor-cafe:before {
  content: "\eca6";
}
.icon-popcorn-soda:before {
  content: "\eca7";
}
.icon-sandbox:before {
  content: "\eca8";
}
.icon-bench:before {
  content: "\eca9";
}
.icon-parasol:before {
  content: "\ecaa";
}
.icon-carnaval-wheel:before {
  content: "\ecab";
}
.icon-terrace:before {
  content: "\ecac";
}
.icon-spotlights:before {
  content: "\ecad";
}
.icon-coffee-shop:before {
  content: "\ecae";
}
.icon-boxing:before {
  content: "\ecaf";
}
.icon-kids-pool:before {
  content: "\ecb0";
}
.icon-bathhouse:before {
  content: "\ecb1";
}
.icon-theatre-stage:before {
  content: "\ecb2";
}
.icon-fountain:before {
  content: "\ecb3";
}
.icon-music-festival:before {
  content: "\ecb4";
}
.icon-exhibition:before {
  content: "\ecb5";
}
.icon-eraser:before {
  content: "\ecb6";
}
.icon-color-wheel:before {
  content: "\ecb7";
}
.icon-painting-brush:before {
  content: "\ecb8";
}
.icon-paragraph:before {
  content: "\ecb9";
}
.icon-font-size:before {
  content: "\ecba";
}
.icon-edit-pen:before {
  content: "\ecbb";
}
.icon-paint-bucket-with-label:before {
  content: "\ecbc";
}
.icon-saturation:before {
  content: "\ecbd";
}
.icon-color-mode:before {
  content: "\ecbe";
}
.icon-unavalible:before {
  content: "\ecc0";
}
.icon-eye-preview:before {
  content: "\ecc1";
}
.icon-eye-hide:before {
  content: "\ecc2";
}
.icon-remove-image:before {
  content: "\ecc3";
}
.icon-darkmode-moon:before {
  content: "\ecc4";
}
.icon-lightmode-sun:before {
  content: "\ecc5";
}
.icon-numbered-list:before {
  content: "\ecc6";
}
.icon-bulleted-list:before {
  content: "\ecc7";
}
.icon-check-list:before {
  content: "\ecc8";
}
.icon-close-window:before {
  content: "\ecc9";
}
.icon-duplicate:before {
  content: "\ecca";
}
.icon-menu-top:before {
  content: "\eccb";
}
.icon-plugin:before {
  content: "\eccc";
}
.icon-responsive-web-design:before {
  content: "\ecce";
}
.icon-grid-one-icon:before {
  content: "\eccf";
}
.icon-grid-icons:before {
  content: "\ecd0";
}
.icon-search-bar:before {
  content: "\ecd4";
}
.icon-search-page:before {
  content: "\ecd5";
}
.icon-website-view-all:before {
  content: "\ecd6";
}
.icon-footer:before {
  content: "\ecd7";
}
.icon-page-template:before {
  content: "\ecd8";
}
.icon-webpage-design:before {
  content: "\ecd9";
}
.icon-webpage-prototype:before {
  content: "\ecdb";
}
.icon-data-grid:before {
  content: "\ecdc";
}
.icon-grid-three-rows:before {
  content: "\ecdd";
}
.icon-grid-four-rows:before {
  content: "\ecde";
}
.icon-columns-four:before {
  content: "\ecdf";
}
.icon-rows-four:before {
  content: "\ece0";
}
.icon-columns-three:before {
  content: "\ece1";
}
.icon-row-three:before {
  content: "\ece2";
}
.icon-columns-eight-four:before {
  content: "\ece3";
}
.icon-columns-four-eight:before {
  content: "\ece4";
}
.icon-columns-two:before {
  content: "\ece5";
}
.icon-rows-two:before {
  content: "\ece6";
}
.icon-webpage-footer:before {
  content: "\ece7";
}
.icon-webpage-header:before {
  content: "\ece8";
}
.icon-webpage-header-footer:before {
  content: "\ece9";
}
.icon-arrow-shortcut:before {
  content: "\ecea";
}
.icon-arrow-curly:before {
  content: "\eceb";
}
.icon-arrows-drag:before {
  content: "\ecec";
}
.icon-arrows-divider-vertical:before {
  content: "\eced";
}
.icon-arrows-merge-horizontal:before {
  content: "\ecee";
}
.icon-arrows-merge-vertical:before {
  content: "\ecef";
}
.icon-arrows-divider-horizontal:before {
  content: "\ecf0";
}
.icon-arrows-left-right:before {
  content: "\ecf1";
}
.icon-triangular-arrows-sort-left:before {
  content: "\ecf3";
}
.icon-triangular-arrows-sort-right:before {
  content: "\ecf4";
}
.icon-triangular-arrows-sort-down:before {
  content: "\ecf5";
}
.icon-triangular-arrows-sort-up:before {
  content: "\ecf6";
}
.icon-triangular-arrows-sort:before {
  content: "\ecf7";
}

.icon-animal-paw:before {
	content: "\e9c3";
}

.icon-birthday-cake:before {
	content: "\ebd1";
}

.icon-birthday-presents:before {
	content: "\ebf0";
}

.icon-party-balloons:before {
	content: "\ebf1";
}

.icon-party-confetti-cone:before {
	content: "\ebf2";
}

.icon-party-confetti:before {
	content: "\ebf3";
}

.icon-temperature-outside:before {
	content: "\ebf5";
}

.icon-dew-point:before {
	content: "\ebf4";
}

.icon-global-warming:before {
	content: "\ebf6";
}

.icon-wildfire:before {
	content: "\ebfe";
}

.icon-house-fires:before {
	content: "\ec2c";
}

.icon-moon-phase:before {
	content: "\ec00";
}

.icon-hurricane:before {
	content: "\ec0b";
}

.icon-tornado:before {
	content: "\ec0c";
}

.icon-dry-wave:before {
	content: "\ec0d";
}

.icon-lightning-bolt:before {
	content: "\ec0e";
}

.icon-icy-icicle:before {
	content: "\ec0f";
}

.icon-snow-storm:before {
	content: "\ec10";
}

.icon-winter:before {
	content: "\ec01";
}

.icon-autumn:before {
	content: "\ec12";
}

.icon-summer:before {
	content: "\ec13";
}

.icon-spring:before {
	content: "\ec14";
}

.icon-rainbow:before {
	content: "\ec15";
}

.icon-day-and-night:before {
	content: "\ec16";
}

.icon-night-cloud-moon-stars:before {
	content: "\ec17";
}

.icon-moon-fog:before {
	content: "\ec18";
}

.icon-moon-and-stars:before {
	content: "\ec19";
}

.icon-cloud-lightning:before {
	content: "\ec1a";
}

.icon-storm-lightning:before {
	content: "\ec1b";
}

.icon-windy-weather:before {
	content: "\ec1c";
}

.icon-floods:before {
	content: "\ec02";
}

.icon-tsunami:before {
	content: "\ec1e";
}

.icon-moisture:before {
	content: "\ec1f";
}

.icon-high-tide:before {
	content: "\ec04";
}

.icon-drought:before {
	content: "\ec08";
}

.icon-earthquakes:before {
	content: "\ec09";
}

.icon-cloud-rain:before {
	content: "\ec24";
}

.icon-cloud-hail:before {
	content: "\ec25";
}

.icon-project-map:before {
	content: "\ec26";
}

.icon-microsoft-powerpoint:before {
	content: "\ec0a";
}

.icon-paper-cv:before {
	content: "\ebf9";
}

.icon-privacy-policy:before {
	content: "\ec11";
}

.icon-gdpr-cloud:before {
	content: "\ebfa";
}

.icon-gdpr:before {
	content: "\ebfb";
}

.icon-pdf-logo:before {
	content: "\ebfc";
}

.icon-pdf:before {
	content: "\ebfd";
}

.icon-mail-pen:before {
	content: "\ec1d";
}

.icon-at-sign:before {
	content: "\ebff";
}

.icon-mail-open-at-sign:before {
	content: "\ec20";
}

.icon-mail-at-sign:before {
	content: "\ec21";
}

.icon-mail-letter:before {
	content: "\ec22";
}

.icon-website-www:before {
	content: "\ec03";
}

.icon-internet-click:before {
	content: "\ec23";
}

.icon-website:before {
	content: "\ec27";
}

.icon-classic-telephone-speaking:before {
	content: "\ec06";
}

.icon-classic-telephone-sound:before {
	content: "\ec07";
}

.icon-classic-telephone:before {
	content: "\ebf8";
}

.icon-x-outline:before {
	content: "\ec29";
}

.icon-checkmark-outline:before {
	content: "\ec2a";
}

.icon-mitid-box:before {
	content: "\ec2b";
}

.icon-mitid:before {
	content: "\ebf7";
}

.icon-youtube:before {
	content: "\ebd6";
}

.icon-twitter:before {
	content: "\ebd7";
}

.icon-linkedin:before {
	content: "\ebd8";
}

.icon-facebook:before {
	content: "\ebd9";
}

.icon-instagram:before {
	content: "\ebda";
}

.icon-twenty-outline:before {
	content: "\ebdb";
}

.icon-nineteen-outline:before {
	content: "\ebdc";
}

.icon-eighteen-outline:before {
	content: "\ebdd";
}

.icon-seventeen-outline:before {
	content: "\ebde";
}

.icon-sixteen-outline:before {
	content: "\ebdf";
}

.icon-fifteen-outline:before {
	content: "\ebe0";
}

.icon-fourteen-outline:before {
	content: "\ebe1";
}

.icon-thirteen-outline:before {
	content: "\ebe2";
}

.icon-twelve-outline:before {
	content: "\ebe3";
}

.icon-eleven-outline:before {
	content: "\ebe4";
}

.icon-ten-outline:before {
	content: "\ebe5";
}

.icon-nine-outline:before {
	content: "\ebe6";
}

.icon-eight-outline:before {
	content: "\ebe7";
}

.icon-seven-outline:before {
	content: "\ebe8";
}

.icon-six-outline:before {
	content: "\ebe9";
}

.icon-five-outline:before {
	content: "\ebea";
}

.icon-four-outline:before {
	content: "\ebeb";
}

.icon-three-outline:before {
	content: "\ebec";
}

.icon-two-outline:before {
	content: "\ebed";
}

.icon-one-outline:before {
	content: "\ebee";
}

.icon-zero-outline:before {
	content: "\ebef";
}

.icon-thread:before {
	content: "\eb9f";
}

.icon-surveillance_camera:before {
	content: "\eba0";
}

.icon-tv:before {
	content: "\eba1";
}

.icon-van:before {
	content: "\eba2";
}

.icon-windsock:before {
	content: "\eba3";
}

.icon-firewall2:before {
	content: "\eba4";
}

.icon-wax_seal:before {
	content: "\eba5";
}

.icon-bookmarks:before {
	content: "\eba6";
}

.icon-view_1_1:before {
	content: "\eba7";
}

.icon-password_field:before {
	content: "\eba8";
}

.icon-wlan_antenna:before {
	content: "\eba9";
}

.icon-button:before {
	content: "\ebaa";
}

.icon-calendar_clock:before {
	content: "\ebab";
}

.icon-timer:before {
	content: "\ebac";
}

.icon-stopwatch:before {
	content: "\ebad";
}

.icon-alarmclock:before {
	content: "\ebae";
}

.icon-arrow_join:before {
	content: "\ebaf";
}

.icon-arrow_fork2:before {
	content: "\ebb0";
}

.icon-arrow_circle2:before {
	content: "\ebb1";
}

.icon-arrow_circle:before {
	content: "\ebb2";
}

.icon-arrow_barrier:before {
	content: "\ebb3";
}

.icon-chart_pyramid:before {
	content: "\ebb4";
}

.icon-chart_pie:before {
	content: "\ebb5";
}

.icon-chart_donut:before {
	content: "\ebb6";
}

.icon-chart_column:before {
	content: "\ebb7";
}

.icon-chart_area:before {
	content: "\ebb8";
}

.icon-chart_line:before {
	content: "\ebb9";
}

.icon-presentation_chart:before {
	content: "\ebba";
}

.icon-presentation2:before {
	content: "\ebbb";
}

.icon-scanner:before {
	content: "\ebbc";
}

.icon-clipboard_check_edit:before {
	content: "\ebbd";
}

.icon-history:before {
	content: "\ebbe";
}

.icon-sticky_note_text:before {
	content: "\ebbf";
}

.icon-sticky_note:before {
	content: "\ebc0";
}

.icon-paper-plane:before {
	content: "\ebc1";
}

.icon-emoticon_straight_face:before {
	content: "\ebc2";
}

.icon-emoticon_blink:before {
	content: "\ebc3";
}

.icon-emoticon_tongue:before {
	content: "\ebc4";
}

.icon-emoticon_angry:before {
	content: "\ebc5";
}

.icon-emoticon_confused:before {
	content: "\ebc6";
}

.icon-emoticon_cry:before {
	content: "\ebc7";
}

.icon-emoticon_frown:before {
	content: "\ebc8";
}

.icon-emoticon_smile:before {
	content: "\ebc9";
}

.icon-hand_thumb_up:before {
	content: "\ebca";
}

.icon-sign-lang:before {
	content: "\ebcb";
}

.icon-plus:before {
	content: "\ebcc";
}

.icon-media_record:before {
	content: "\ebcd";
}

.icon-media_fast_forward:before {
	content: "\ebce";
}

.icon-media_pause:before {
	content: "\ebcf";
}

.icon-media_play:before {
	content: "\ebd0";
}

.icon-photos:before {
	content: "\ebd2";
}

.icon-video-player:before {
	content: "\ebd3";
}

.icon-play-button:before {
	content: "\ebd4";
}

.icon-shell:before {
	content: "\ebd5";
}

.icon-drop:before {
	content: "\e9bc";
}

.icon-dot_matrix:before {
	content: "\e9bd";
}

.icon-cabinet2:before {
	content: "\e9be";
}

.icon-blot:before {
	content: "\e9bf";
}

.icon-balloon:before {
	content: "\e9c0";
}

.icon-balloons:before {
	content: "\e9c1";
}

.icon-temple:before {
	content: "\e9c2";
}

.icon-knot:before {
	content: "\ec05";
}

.icon-crack:before {
	content: "\e9c4";
}

.icon-marionette:before {
	content: "\e9c5";
}

.icon-piece:before {
	content: "\e9c6";
}

.icon-pieces:before {
	content: "\e9c7";
}

.icon-chess_piece_knight:before {
	content: "\e9c8";
}

.icon-gambling_chips:before {
	content: "\e9c9";
}

.icon-gambling_chip:before {
	content: "\e9ca";
}

.icon-playing_cards:before {
	content: "\e9cb";
}

.icon-trophy:before {
	content: "\e9cc";
}

.icon-golf_club_iron:before {
	content: "\e9cd";
}

.icon-medal3:before {
	content: "\e9ce";
}

.icon-medal2:before {
	content: "\e9cf";
}

.icon-whistle_noise:before {
	content: "\e9d0";
}

.icon-whistle:before {
	content: "\e9d1";
}

.icon-eightball:before {
	content: "\e9d2";
}

.icon-goal:before {
	content: "\e9d3";
}

.icon-dumbbell:before {
	content: "\e9d4";
}

.icon-target2:before {
	content: "\e9d5";
}

.icon-bowling_pin:before {
	content: "\e9d6";
}

.icon-bowling_pins:before {
	content: "\e9d7";
}

.icon-soccer_ball:before {
	content: "\e9d8";
}

.icon-golf_ball:before {
	content: "\e9d9";
}

.icon-goal_ball:before {
	content: "\e9da";
}

.icon-bowling_ball:before {
	content: "\e9db";
}

.icon-tennis_ball:before {
	content: "\e9dc";
}

.icon-basketball:before {
	content: "\e9dd";
}

.icon-baseball:before {
	content: "\e9de";
}

.icon-graph_triangle:before {
	content: "\e9df";
}

.icon-graph_to:before {
	content: "\e9e0";
}

.icon-graph_star:before {
	content: "\e9e1";
}

.icon-graph_square:before {
	content: "\e9e2";
}

.icon-graph_self_loop:before {
	content: "\e9e3";
}

.icon-graph_from:before {
	content: "\e9e4";
}

.icon-graph_fork2:before {
	content: "\e9e5";
}

.icon-graph_fork:before {
	content: "\e9e6";
}

.icon-graph_connection_directed:before {
	content: "\e9e7";
}

.icon-graph_claw:before {
	content: "\e9e8";
}

.icon-graph2:before {
	content: "\e9e9";
}

.icon-cubes:before {
	content: "\e9ea";
}

.icon-objects3:before {
	content: "\e9eb";
}

.icon-objects4:before {
	content: "\e9ec";
}

.icon-objects2:before {
	content: "\e9ed";
}

.icon-objects:before {
	content: "\e9ee";
}

.icon-object_cylinder:before {
	content: "\e9ef";
}

.icon-object_cube:before {
	content: "\e9f0";
}

.icon-object_cone:before {
	content: "\e9f1";
}

.icon-object_ball:before {
	content: "\e9f2";
}

.icon-shape_octagon:before {
	content: "\e9f3";
}

.icon-shape_hexagon:before {
	content: "\e9f4";
}

.icon-shape_ellipse:before {
	content: "\e9f5";
}

.icon-shapes:before {
	content: "\e9f6";
}

.icon-shape_square:before {
	content: "\e9f7";
}

.icon-shape_rhomb:before {
	content: "\e9f8";
}

.icon-shape_rectangle:before {
	content: "\e9f9";
}

.icon-shape_pentagon:before {
	content: "\e9fa";
}

.icon-erase:before {
	content: "\e9fb";
}

.icon-spray_can:before {
	content: "\e9fc";
}

.icon-airbrush:before {
	content: "\e9fd";
}

.icon-marker:before {
	content: "\e9fe";
}

.icon-pipette:before {
	content: "\e9ff";
}

.icon-ballpen:before {
	content: "\ea00";
}

.icon-pencil:before {
	content: "\ea01";
}

.icon-brush:before {
	content: "\ea02";
}

.icon-paint_brush:before {
	content: "\ea03";
}

.icon-painters_palette_brush:before {
	content: "\ea04";
}

.icon-painters_palette:before {
	content: "\ea05";
}

.icon-painting_landscape:before {
	content: "\ea06";
}

.icon-painting_portrait:before {
	content: "\ea07";
}

.icon-paint_bucket:before {
	content: "\ea08";
}

.icon-star_and_crescent:before {
	content: "\ea09";
}

.icon-star_of_david:before {
	content: "\ea0a";
}

.icon-christian_cross:before {
	content: "\ea0b";
}

.icon-bible:before {
	content: "\ea0c";
}

.icon-yinyang:before {
	content: "\ea0d";
}

.icon-devil:before {
	content: "\ea0e";
}

.icon-angel:before {
	content: "\ea0f";
}

.icon-pastor:before {
	content: "\ea10";
}

.icon-sausage:before {
	content: "\ea11";
}

.icon-pretzel:before {
	content: "\ea12";
}

.icon-goblet:before {
	content: "\ea13";
}

.icon-knife_fork_spoon:before {
	content: "\ea14";
}

.icon-knife_fork:before {
	content: "\ea15";
}

.icon-glass_fork:before {
	content: "\ea16";
}

.icon-glass_cracked:before {
	content: "\ea17";
}

.icon-bottle_bubbles:before {
	content: "\ea18";
}

.icon-cigarette:before {
	content: "\ea19";
}

.icon-wine_glass:before {
	content: "\ea1a";
}

.icon-wine_bottle:before {
	content: "\ea1b";
}

.icon-hamburger:before {
	content: "\ea1e";
}

.icon-bacon:before {
	content: "\ea1f";
}

.icon-fried_egg:before {
	content: "\ea20";
}

.icon-fried_egg_bacon:before {
	content: "\ea21";
}

.icon-cake_slice:before {
	content: "\ea22";
}

.icon-cask:before {
	content: "\ea23";
}

.icon-cup:before {
	content: "\ea24";
}

.icon-drink:before {
	content: "\ea25";
}

.icon-beverage_can:before {
	content: "\ea26";
}

.icon-lemon:before {
	content: "\ea27";
}

.icon-banana:before {
	content: "\ea28";
}

.icon-apple_bite:before {
	content: "\ea29";
}

.icon-lollipop:before {
	content: "\ea2a";
}

.icon-ice_cream:before {
	content: "\ea2b";
}

.icon-ice_cream2:before {
	content: "\ea2c";
}

.icon-cocktail2:before {
	content: "\ea2d";
}

.icon-cocktail:before {
	content: "\ea2e";
}

.icon-champagne_glass:before {
	content: "\ea2f";
}

.icon-coathanger:before {
	content: "\ea30";
}

.icon-handbag:before {
	content: "\ea31";
}

.icon-hat:before {
	content: "\ea32";
}

.icon-mask:before {
	content: "\ea33";
}

.icon-sunglasses:before {
	content: "\ea34";
}

.icon-eyeglasses:before {
	content: "\ea35";
}

.icon-bow_tie:before {
	content: "\ea36";
}

.icon-hand_fist2:before {
	content: "\ea37";
}

.icon-hand_point_left:before {
	content: "\ea38";
}

.icon-hand_point_right:before {
	content: "\ea39";
}

.icon-hand_spread:before {
	content: "\ea3a";
}

.icon-hand_stop2:before {
	content: "\ea3b";
}

.icon-hand_card:before {
	content: "\ea3c";
}

.icon-hand_offer:before {
	content: "\ea3d";
}

.icon-hand_thumb_down:before {
	content: "\ea3e";
}

.icon-hand_point_up:before {
	content: "\ea3f";
}

.icon-terrorist:before {
	content: "\ea40";
}

.icon-criminal:before {
	content: "\ea41";
}

.icon-girl:before {
	content: "\ea42";
}

.icon-boy:before {
	content: "\ea43";
}

.icon-crash_test_dummy:before {
	content: "\ea44";
}

.icon-bicyclist:before {
	content: "\ea45";
}

.icon-holmes:before {
	content: "\ea46";
}

.icon-genius:before {
	content: "\ea47";
}

.icon-dude6:before {
	content: "\ea48";
}

.icon-dude5:before {
	content: "\ea49";
}

.icon-dude4:before {
	content: "\ea4a";
}

.icon-dude3:before {
	content: "\ea4b";
}

.icon-dude2:before {
	content: "\ea4c";
}

.icon-robot:before {
	content: "\ea4d";
}

.icon-baby2:before {
	content: "\ea4e";
}

.icon-magician:before {
	content: "\ea4f";
}

.icon-astrologer:before {
	content: "\ea50";
}

.icon-users:before {
	content: "\ea51";
}

.icon-users_crowd:before {
	content: "\ea52";
}

.icon-users_relation2:before {
	content: "\ea53";
}

.icon-users_relation:before {
	content: "\ea54";
}

.icon-users_family:before {
	content: "\ea55";
}

.icon-users_meeting:before {
	content: "\ea56";
}

.icon-users3:before {
	content: "\ea57";
}

.icon-users2:before {
	content: "\ea58";
}

.icon-users4:before {
	content: "\ea59";
}

.icon-user_smartphone:before {
	content: "\ea5a";
}

.icon-user_headphones:before {
	content: "\ea5b";
}

.icon-user_monitor:before {
	content: "\ea5c";
}

.icon-user_headset:before {
	content: "\ea5d";
}

.icon-user_telephone:before {
	content: "\ea5e";
}

.icon-user_message:before {
	content: "\ea5f";
}

.icon-user_earth:before {
	content: "\ea60";
}

.icon-user:before {
	content: "\ea61";
}

.icon-cook:before {
	content: "\ea62";
}

.icon-guard:before {
	content: "\ea63";
}

.icon-spy:before {
	content: "\ea64";
}

.icon-security_agent:before {
	content: "\ea65";
}

.icon-pilot:before {
	content: "\ea66";
}

.icon-engineer:before {
	content: "\ea67";
}

.icon-worker:before {
	content: "\ea68";
}

.icon-surgeon:before {
	content: "\ea69";
}

.icon-doctor:before {
	content: "\ea6a";
}

.icon-bookkeeper:before {
	content: "\ea6b";
}

.icon-stockbroker2:before {
	content: "\ea6c";
}

.icon-stockbroker:before {
	content: "\ea6d";
}

.icon-businessperson:before {
	content: "\ea6e";
}

.icon-athlete:before {
	content: "\ea6f";
}

.icon-scientist:before {
	content: "\ea70";
}

.icon-odometer:before {
	content: "\ea71";
}

.icon-equalizer:before {
	content: "\ea72";
}

.icon-control_panel2:before {
	content: "\ea73";
}

.icon-control_panel:before {
	content: "\ea74";
}

.icon-emergency_stop_button:before {
	content: "\ea75";
}

.icon-switch3_on:before {
	content: "\ea76";
}

.icon-switch3_off:before {
	content: "\ea77";
}

.icon-switch_off:before {
	content: "\ea78";
}

.icon-switch_on:before {
	content: "\ea79";
}

.icon-remote_control:before {
	content: "\ea7a";
}

.icon-bus2:before {
	content: "\ea7b";
}

.icon-submarine:before {
	content: "\ea7c";
}

.icon-small_truck:before {
	content: "\ea7d";
}

.icon-moving_truck:before {
	content: "\ea7e";
}

.icon-truck_container:before {
	content: "\ea7f";
}

.icon-truck2:before {
	content: "\ea80";
}

.icon-dump_truck:before {
	content: "\ea81";
}

.icon-delivery_truck:before {
	content: "\ea82";
}

.icon-truck:before {
	content: "\ea83";
}

.icon-car_sedan2:before {
	content: "\ea84";
}

.icon-motor_scooter:before {
	content: "\ea85";
}

.icon-tank_truck:before {
	content: "\ea86";
}

.icon-tractor:before {
	content: "\ea87";
}

.icon-minibus:before {
	content: "\ea88";
}

.icon-car_sedan:before {
	content: "\ea89";
}

.icon-bus:before {
	content: "\ea8a";
}

.icon-gearwheels:before {
	content: "\ea8b";
}

.icon-pressure_sprayer:before {
	content: "\ea8c";
}

.icon-power_drill:before {
	content: "\ea8d";
}

.icon-bucket:before {
	content: "\ea8e";
}

.icon-paint_roller:before {
	content: "\ea8f";
}

.icon-axe:before {
	content: "\ea90";
}

.icon-utility_knife:before {
	content: "\ea91";
}

.icon-caliper:before {
	content: "\ea92";
}

.icon-spirit_level:before {
	content: "\ea93";
}

.icon-windup_key:before {
	content: "\ea94";
}

.icon-knife:before {
	content: "\ea95";
}

.icon-weight:before {
	content: "\ea96";
}

.icon-weight2:before {
	content: "\ea97";
}

.icon-toolbox:before {
	content: "\ea98";
}

.icon-folding_rule:before {
	content: "\ea99";
}

.icon-tape_measure:before {
	content: "\ea9a";
}

.icon-tape_measure2:before {
	content: "\ea9b";
}

.icon-screwdriver:before {
	content: "\ea9c";
}

.icon-nail:before {
	content: "\ea9d";
}

.icon-hammer:before {
	content: "\ea9e";
}

.icon-flashlight:before {
	content: "\ea9f";
}

.icon-wrench2:before {
	content: "\eaa0";
}

.icon-beaker2:before {
	content: "\eaa1";
}

.icon-beaker:before {
	content: "\eaa2";
}

.icon-microscope:before {
	content: "\eaa3";
}

.icon-gauge:before {
	content: "\eaa4";
}

.icon-radiation:before {
	content: "\eaa5";
}

.icon-battery_status5:before {
	content: "\eaa6";
}

.icon-battery_status1:before {
	content: "\eaa7";
}

.icon-battery_plus_minus:before {
	content: "\eaa8";
}

.icon-battery_charge:before {
	content: "\eaa9";
}

.icon-fuel_dispenser:before {
	content: "\eaaa";
}

.icon-jerrycan:before {
	content: "\eaab";
}

.icon-barrel:before {
	content: "\eaac";
}

.icon-cooling_tower_smoke:before {
	content: "\eaad";
}

.icon-cooling_tower:before {
	content: "\eaae";
}

.icon-wind_engine_offshore:before {
	content: "\eaaf";
}

.icon-magnet:before {
	content: "\eab0";
}

.icon-astronaut:before {
	content: "\eab1";
}

.icon-binocular:before {
	content: "\eab2";
}

.icon-compass:before {
	content: "\eab3";
}

.icon-ufo:before {
	content: "\eab4";
}

.icon-rocket:before {
	content: "\eab5";
}

.icon-cube_molecule2:before {
	content: "\eab6";
}

.icon-cube_molecule:before {
	content: "\eab7";
}

.icon-molecule_h2o:before {
	content: "\eab8";
}

.icon-atom2:before {
	content: "\eab9";
}

.icon-atom:before {
	content: "\eaba";
}

.icon-jackhammer:before {
	content: "\eabb";
}

.icon-excavator:before {
	content: "\eabc";
}

.icon-wheel_loader:before {
	content: "\eabd";
}

.icon-road_roller:before {
	content: "\eabe";
}

.icon-mobile_crane:before {
	content: "\eabf";
}

.icon-bulldozer:before {
	content: "\eac0";
}

.icon-tower_crane:before {
	content: "\eac1";
}

.icon-construction_barrier:before {
	content: "\eac2";
}

.icon-pylon:before {
	content: "\eac3";
}

.icon-thermometer:before {
	content: "\e9bb";
}

.icon-umbrella_open:before {
	content: "\eac4";
}

.icon-moon_half:before {
	content: "\eac5";
}

.icon-moon:before {
	content: "\eac6";
}

.icon-rain_drops:before {
	content: "\eac7";
}

.icon-snowflake:before {
	content: "\eac8";
}

.icon-cloud_snow:before {
	content: "\eac9";
}

.icon-cloud:before {
	content: "\eaca";
}

.icon-sun:before {
	content: "\eacb";
}

.icon-water_fish:before {
	content: "\eacc";
}

.icon-water:before {
	content: "\eacd";
}

.icon-leaf:before {
	content: "\eace";
}

.icon-cactus:before {
	content: "\eacf";
}

.icon-mosquito:before {
	content: "\ead0";
}

.icon-spider:before {
	content: "\ead1";
}

.icon-mushroom:before {
	content: "\ead2";
}

.icon-fish_bowl:before {
	content: "\ead3";
}

.icon-plant:before {
	content: "\ead4";
}

.icon-flower2:before {
	content: "\ead5";
}

.icon-package:before {
	content: "\ead6";
}

.icon-gift:before {
	content: "\ead7";
}

.icon-box_open:before {
	content: "\ead8";
}

.icon-box_closed:before {
	content: "\ead9";
}

.icon-shelf_full:before {
	content: "\eada";
}

.icon-shelf_empty:before {
	content: "\eadb";
}

.icon-platform_truck_suitcase:before {
	content: "\eadc";
}

.icon-hand_truck:before {
	content: "\eadd";
}

.icon-platform_truck_box:before {
	content: "\eade";
}

.icon-barcode:before {
	content: "\eadf";
}

.icon-card_terminal2:before {
	content: "\eae0";
}

.icon-credit_cards:before {
	content: "\eae1";
}

.icon-cash_register:before {
	content: "\eae2";
}

.icon-auction_hammer:before {
	content: "\eae3";
}

.icon-shopping_bag_full:before {
	content: "\eae4";
}

.icon-shopping_bag:before {
	content: "\eae5";
}

.icon-market_stand:before {
	content: "\eae6";
}

.icon-store:before {
	content: "\eae7";
}

.icon-desk_lamp:before {
	content: "\eae8";
}

.icon-shredder:before {
	content: "\eae9";
}

.icon-notebook2:before {
	content: "\eaea";
}

.icon-notebook:before {
	content: "\eaeb";
}

.icon-desktop:before {
	content: "\eaec";
}

.icon-mug2:before {
	content: "\eaed";
}

.icon-coffee_machine:before {
	content: "\eaee";
}

.icon-stapler:before {
	content: "\eaef";
}

.icon-office_building:before {
	content: "\eaf0";
}

.icon-office_building2:before {
	content: "\eaf1";
}

.icon-office_chair:before {
	content: "\eaf2";
}

.icon-laser_pointer:before {
	content: "\eaf3";
}

.icon-cabinet_open:before {
	content: "\eaf4";
}

.icon-paperclip:before {
	content: "\eaf5";
}

.icon-diamond_ring:before {
	content: "\eaf6";
}

.icon-crown:before {
	content: "\eaf7";
}

.icon-safe:before {
	content: "\eaf8";
}

.icon-diamond:before {
	content: "\eaf9";
}

.icon-central_bank:before {
	content: "\eafa";
}

.icon-purse:before {
	content: "\eafb";
}

.icon-wallet:before {
	content: "\eafc";
}

.icon-piggy_bank:before {
	content: "\eafd";
}

.icon-money:before {
	content: "\eafe";
}

.icon-shutter:before {
	content: "\eaff";
}

.icon-film_cartridge:before {
	content: "\eb00";
}

.icon-camera:before {
	content: "\eb01";
}

.icon-photo_landscape:before {
	content: "\eb02";
}

.icon-photo_portrait:before {
	content: "\eb03";
}

.icon-video_chat2:before {
	content: "\eb04";
}

.icon-video_chat:before {
	content: "\eb05";
}

.icon-clapperboard:before {
	content: "\eb06";
}

.icon-film:before {
	content: "\eb07";
}

.icon-movie_camera:before {
	content: "\eb08";
}

.icon-webcam:before {
	content: "\eb09";
}

.icon-flatscreen_tv:before {
	content: "\eb0a";
}

.icon-music:before {
	content: "\eb0b";
}

.icon-headphones:before {
	content: "\eb0c";
}

.icon-radio:before {
	content: "\eb0d";
}

.icon-boombox:before {
	content: "\eb0e";
}

.icon-record:before {
	content: "\eb0f";
}

.icon-clef:before {
	content: "\eb10";
}

.icon-loudspeaker_box:before {
	content: "\eb11";
}

.icon-singer:before {
	content: "\eb12";
}

.icon-book_telephone:before {
	content: "\eb13";
}

.icon-smartphone:before {
	content: "\eb14";
}

.icon-thought:before {
	content: "\eb15";
}

.icon-messages:before {
	content: "\eb16";
}

.icon-message:before {
	content: "\eb17";
}

.icon-plug2:before {
	content: "\eb18";
}

.icon-plug:before {
	content: "\eb19";
}

.icon-web:before {
	content: "\eb1a";
}

.icon-cookies:before {
	content: "\eb1b";
}

.icon-environment:before {
	content: "\eb1c";
}

.icon-reticle:before {
	content: "\e9ba";
}

.icon-missile:before {
	content: "\e9b9";
}

.icon-torpedo:before {
	content: "\e9b8";
}

.icon-sword:before {
	content: "\e9b7";
}

.icon-bomb:before {
	content: "\eb1d";
}

.icon-gun:before {
	content: "\eb1e";
}

.icon-dynamite:before {
	content: "\eb1f";
}

.icon-home_water:before {
	content: "\eb20";
}

.icon-trojan_horse:before {
	content: "\eb21";
}

.icon-explosion:before {
	content: "\eb22";
}

.icon-flame:before {
	content: "\eb23";
}

.icon-home_fire:before {
	content: "\eb24";
}

.icon-fire:before {
	content: "\eb25";
}

.icon-fire_extinguisher:before {
	content: "\eb26";
}

.icon-surveillance_camera2:before {
	content: "\eb27";
}

.icon-dome_camera:before {
	content: "\eb28";
}

.icon-shield:before {
	content: "\eb29";
}

.icon-castle:before {
	content: "\eb2a";
}

.icon-wall_barbed_wire:before {
	content: "\e9b6";
}

.icon-keyhole:before {
	content: "\e9b5";
}

.icon-home_lock:before {
	content: "\e9b4";
}

.icon-lock_open:before {
	content: "\e9b3";
}

.icon-lock:before {
	content: "\eb2b";
}

.icon-keys:before {
	content: "\eb2c";
}

.icon-fingerprint_scan:before {
	content: "\eb2d";
}

.icon-eye_scan:before {
	content: "\eb2e";
}

.icon-face_scan:before {
	content: "\eb2f";
}

.icon-fingerprint:before {
	content: "\eb30";
}

.icon-passport:before {
	content: "\eb31";
}

.icon-id_cards:before {
	content: "\eb32";
}

.icon-id_card:before {
	content: "\eb33";
}

.icon-index2:before {
	content: "\eb34";
}

.icon-coffee_beans:before {
	content: "\eb35";
}

.icon-jar:before {
	content: "\eb36";
}

.icon-bug2:before {
	content: "\eb37";
}

.icon-bug:before {
	content: "\eb38";
}

.icon-antenna:before {
	content: "\e9b2";
}

.icon-mouse:before {
	content: "\e9b1";
}

.icon-gamepad:before {
	content: "\e9b0";
}

.icon-joystick:before {
	content: "\e9af";
}

.icon-keyboard:before {
	content: "\eb39";
}

.icon-tablet_computer:before {
	content: "\eb3a";
}

.icon-laptop:before {
	content: "\eb3b";
}

.icon-workstation:before {
	content: "\eb3c";
}

.icon-workplace:before {
	content: "\eb3d";
}

.icon-ships_wheel:before {
	content: "\eb3e";
}

.icon-buoy:before {
	content: "\eb3f";
}

.icon-signpost3:before {
	content: "\eb40";
}

.icon-signpost2:before {
	content: "\eb41";
}

.icon-signpost:before {
	content: "\eb42";
}

.icon-map_location:before {
	content: "\eb43";
}

.icon-signaling_disc:before {
	content: "\eb44";
}

.icon-barrier_closed:before {
	content: "\eb45";
}

.icon-barrier_open:before {
	content: "\eb46";
}

.icon-candle:before {
	content: "\e9ae";
}

.icon-candle_holder:before {
	content: "\e9ad";
}

.icon-lighthouse:before {
	content: "\e9ac";
}

.icon-led:before {
	content: "\e9ab";
}

.icon-service_bell:before {
	content: "\eb47";
}

.icon-bell:before {
	content: "\eb48";
}

.icon-signal_flag_checkered:before {
	content: "\eb49";
}

.icon-signal_flag:before {
	content: "\eb4a";
}

.icon-sign_yield:before {
	content: "\eb4b";
}

.icon-sign_warning_voltage:before {
	content: "\eb4c";
}

.icon-sign_warning_toxic:before {
	content: "\eb4d";
}

.icon-sign_warning_radiation:before {
	content: "\eb4e";
}

.icon-sign_warning_harmful:before {
	content: "\eb4f";
}

.icon-sign_warning_flammable:before {
	content: "\eb50";
}

.icon-sign_warning_biohazard:before {
	content: "\eb51";
}

.icon-sign_warning:before {
	content: "\eb52";
}

.icon-sign_stop:before {
	content: "\eb53";
}

.icon-door_closed:before {
	content: "\eb54";
}

.icon-door_exit:before {
	content: "\e9aa";
}

.icon-door_open:before {
	content: "\e9a9";
}

.icon-window_key:before {
	content: "\e9a8";
}

.icon-heart_broken:before {
	content: "\e9a7";
}

.icon-heart1:before {
	content: "\eb55";
}

.icon-star2:before {
	content: "\eb56";
}

.icon-speech_balloon_question:before {
	content: "\eb57";
}

.icon-speech_balloon_answer:before {
	content: "\eb58";
}

.icon-speech_balloon:before {
	content: "\eb59";
}

.icon-magic_wand:before {
	content: "\eb5a";
}

.icon-lightbulb_on:before {
	content: "\eb5b";
}

.icon-about:before {
	content: "\eb5c";
}

.icon-question:before {
	content: "\eb5d";
}

.icon-information:before {
	content: "\eb5e";
}

.icon-garbage_can:before {
	content: "\eb5f";
}

.icon-garbage_overflow:before {
	content: "\eb60";
}

.icon-garbage_container:before {
	content: "\eb61";
}

.icon-garbage_full:before {
	content: "\eb62";
}

.icon-garbage_half_full:before {
	content: "\e9a6";
}

.icon-garbage:before {
	content: "\e9a5";
}

.icon-windows:before {
	content: "\e9a4";
}

.icon-window_star:before {
	content: "\e9a3";
}

.icon-window_warning:before {
	content: "\eb63";
}

.icon-window_gear:before {
	content: "\eb64";
}

.icon-window:before {
	content: "\eb65";
}

.icon-ticket:before {
	content: "\eb66";
}

.icon-dictionary:before {
	content: "\eb67";
}

.icon-book_bookmark:before {
	content: "\eb68";
}

.icon-book_headphones:before {
	content: "\eb69";
}

.icon-book2:before {
	content: "\eb6a";
}

.icon-documents_empty:before {
	content: "\eb6b";
}

.icon-document_zip:before {
	content: "\eb6c";
}

.icon-document_empty:before {
	content: "\eb6d";
}

.icon-document_text:before {
	content: "\eb6e";
}

.icon-document_pulse:before {
	content: "\eb6f";
}

.icon-document_pinned:before {
	content: "\eb70";
}

.icon-document_notebook:before {
	content: "\e9a2";
}

.icon-document_music:before {
	content: "\e9a1";
}

.icon-document_heart:before {
	content: "\e9a0";
}

.icon-document_gear:before {
	content: "\e99f";
}

.icon-documents_exchange:before {
	content: "\eb71";
}

.icon-document_cup:before {
	content: "\eb72";
}

.icon-document_chart:before {
	content: "\eb73";
}

.icon-document_attachment:before {
	content: "\eb74";
}

.icon-formula:before {
	content: "\eb75";
}

.icon-quotation_marks:before {
	content: "\eb76";
}

.icon-list_style_numbered:before {
	content: "\eb77";
}

.icon-spellcheck:before {
	content: "\eb78";
}

.icon-text_speaker:before {
	content: "\eb79";
}

.icon-text_braille:before {
	content: "\eb7a";
}

.icon-text2:before {
	content: "\eb7b";
}

.icon-scroll:before {
	content: "\eb7c";
}

.icon-scroll2:before {
	content: "\eb7d";
}

.icon-lectern:before {
	content: "\eb7e";
}

.icon-lecture:before {
	content: "\e99e";
}

.icon-graduation_hat:before {
	content: "\e99d";
}

.icon-graduate:before {
	content: "\e99c";
}

.icon-blackboard:before {
	content: "\e99b";
}

.icon-disability:before {
	content: "\eb7f";
}

.icon-pacemaker:before {
	content: "\eb80";
}

.icon-ear:before {
	content: "\eb81";
}

.icon-bacteria:before {
	content: "\eb82";
}

.icon-dna:before {
	content: "\eb83";
}

.icon-tooth:before {
	content: "\eb84";
}

.icon-tooth_carious:before {
	content: "\eb85";
}

.icon-pill2:before {
	content: "\eb86";
}

.icon-pill:before {
	content: "\eb87";
}

.icon-bone:before {
	content: "\eb88";
}

.icon-skull:before {
	content: "\eb89";
}

.icon-skull2:before {
	content: "\eb8a";
}

.icon-eye_blind:before {
	content: "\eb8b";
}

.icon-eye:before {
	content: "\eb8c";
}

.icon-thermometer2:before {
	content: "\e99a";
}

.icon-medical_bag:before {
	content: "\e999";
}

.icon-gauze_bandage:before {
	content: "\e998";
}

.icon-ambulance:before {
	content: "\e997";
}

.icon-bed_empty:before {
	content: "\eb8d";
}

.icon-bed:before {
	content: "\eb8e";
}

.icon-hospital:before {
	content: "\eb8f";
}

.icon-scales:before {
	content: "\eb90";
}

.icon-tent:before {
	content: "\eb91";
}

.icon-suitcase2:before {
	content: "\eb92";
}

.icon-suitcase:before {
	content: "\eb93";
}

.icon-soap_dispenser:before {
	content: "\eb94";
}

.icon-perfume:before {
	content: "\eb95";
}

.icon-electric_iron:before {
	content: "\eb96";
}

.icon-comb:before {
	content: "\eb97";
}

.icon-body_scale:before {
	content: "\eb98";
}

.icon-watering_can:before {
	content: "\eb99";
}

.icon-feeding_bottle:before {
	content: "\eb9a";
}

.icon-clipboard_paste:before {
	content: "\e996";
}

.icon-copy:before {
	content: "\e995";
}

.icon-edit:before {
	content: "\e994";
}

.icon-cut:before {
	content: "\e993";
}

.icon-time2:before {
	content: "\eb9b";
}

.icon-syringe:before {
	content: "\eb9c";
}

.icon-heart:before {
	content: "\eb9d";
}

.icon-virus1:before {
	content: "\eb9e";
}

.icon-drama1:before {
	content: "\e992";
}

.icon-calculator1:before {
	content: "\e991";
}

.icon-coins1:before {
	content: "\e990";
}

.icon-teddybear1:before {
	content: "\e98f";
}

.icon-children1:before {
	content: "\e98e";
}

.icon-brickwall1:before {
	content: "\e98d";
}

.icon-medal1:before {
	content: "\e98c";
}

.icon-trash1:before {
	content: "\e98b";
}

.icon-trafficlight1:before {
	content: "\e98a";
}

.icon-help1:before {
	content: "\e989";
}

.icon-airplane1:before {
	content: "\e988";
}

.icon-cross1:before {
	content: "\e987";
}

.icon-book1:before {
	content: "\e986";
}

.icon-house1:before {
	content: "\e985";
}

.icon-folder1:before {
	content: "\e984";
}

.icon-microphone1:before {
	content: "\e983";
}

.icon-icon-lock1:before {
	content: "\e982";
}

.icon-mail11:before {
	content: "\e981";
}

.icon-printer1:before {
	content: "\e980";
}

.icon-speaker1:before {
	content: "\e97f";
}

.icon-text1:before {
	content: "\e97e";
}

.icon-share1:before {
	content: "\e97d";
}

.icon-case1:before {
	content: "\e97c";
}

.icon-contact1:before {
	content: "\e97b";
}

.icon-graph1:before {
	content: "\e97a";
}

.icon-nature1:before {
	content: "\e979";
}

.icon-pin1:before {
	content: "\e978";
}

.icon-time1:before {
	content: "\e977";
}

.icon-chat1:before {
	content: "\e976";
}

.icon-phone1:before {
	content: "\e975";
}

.icon-link1:before {
	content: "\e974";
}

.icon-mail2:before {
	content: "\e973";
}

.icon-gear1:before {
	content: "\e972";
}

.icon-wrench1:before {
	content: "\e971";
}

.icon-tag1:before {
	content: "\e970";
}

.icon-factory1:before {
	content: "\e96f";
}

.icon-partnership1:before {
	content: "\e96e";
}

.icon-calendar1:before {
	content: "\e96d";
}

.icon-bike1:before {
	content: "\e96c";
}

.icon-mug1:before {
	content: "\e96b";
}

.icon-ruler1:before {
	content: "\e96a";
}

.icon-lightbulb1:before {
	content: "\e969";
}

.icon-recycle1:before {
	content: "\e968";
}

.icon-sign1:before {
	content: "\e967";
}

.icon-sing1:before {
	content: "\e966";
}

.icon-heartbeat1:before {
	content: "\e965";
}

.icon-agreement1:before {
	content: "\e964";
}

.icon-baby1:before {
	content: "\e963";
}

.icon-plaster1:before {
	content: "\e962";
}

.icon-stethoscope1:before {
	content: "\e961";
}

.icon-target1:before {
	content: "\e960";
}

.icon-dummy1:before {
	content: "\e95f";
}

.icon-family1:before {
	content: "\e95e";
}

.icon-ball1:before {
	content: "\e95d";
}

.icon-fence1:before {
	content: "\e95c";
}

.icon-farm1:before {
	content: "\e95b";
}

.icon-flower1:before {
	content: "\e95a";
}

.icon-apple1:before {
	content: "\e959";
}

.icon-wheat1:before {
	content: "\e958";
}

.icon-basket1:before {
	content: "\e957";
}

.icon-tap1:before {
	content: "\e956";
}

.icon-bonfire1:before {
	content: "\e955";
}

.icon-swimming1:before {
	content: "\e954";
}

.icon-tree1:before {
	content: "\e953";
}

.icon-weather1:before {
	content: "\e952";
}

.icon-worldwide1:before {
	content: "\e951";
}

.icon-storm1:before {
	content: "\e950";
}

.icon-kite1:before {
	content: "\e94f";
}

.icon-windmill1:before {
	content: "\e94e";
}

.icon-toy1:before {
	content: "\e94d";
}

.icon-arrow1:before {
	content: "\e94c";
}

.icon-search1:before {
	content: "\e94b";
}

.icon-icon-document1:before {
	content: "\e94a";
}

.icon-virus:before {
	content: "\e916";
}

.icon-drama:before {
	content: "\e937";
}

.icon-calculator:before {
	content: "\e93a";
}

.icon-coins:before {
	content: "\e93b";
}

.icon-teddybear:before {
	content: "\e93c";
}

.icon-children:before {
	content: "\e93d";
}

.icon-brickwall:before {
	content: "\e93e";
}

.icon-medal:before {
	content: "\e93f";
}

.icon-trash:before {
	content: "\e940";
}

.icon-trafficlight:before {
	content: "\e941";
}

.icon-help:before {
	content: "\e942";
}

.icon-airplane:before {
	content: "\e943";
}

.icon-cross:before {
	content: "\e944";
}

.icon-book:before {
	content: "\e945";
}

.icon-house:before {
	content: "\e946";
}

.icon-folder:before {
	content: "\e947";
}

.icon-microphone:before {
	content: "\e948";
}

.icon-icon-lock:before {
	content: "\e949";
}

.icon-mail1:before {
	content: "\e900";
}

.icon-printer:before {
	content: "\e901";
}

.icon-speaker:before {
	content: "\e902";
}

.icon-text:before {
	content: "\e903";
}

.icon-share:before {
	content: "\e904";
}

.icon-case:before {
	content: "\e905";
}

.icon-contact:before {
	content: "\e906";
}

.icon-graph:before {
	content: "\e907";
}

.icon-nature:before {
	content: "\e908";
}

.icon-pin:before {
	content: "\e909";
}

.icon-time:before {
	content: "\e90a";
}

.icon-chat:before {
	content: "\e90b";
}

.icon-phone:before {
	content: "\e90c";
}

.icon-link:before {
	content: "\e90d";
}

.icon-mail:before {
	content: "\e90e";
}

.icon-gear:before {
	content: "\e90f";
}

.icon-wrench:before {
	content: "\e910";
}

.icon-tag:before {
	content: "\e911";
}

.icon-factory:before {
	content: "\e912";
}

.icon-partnership:before {
	content: "\e913";
}

.icon-calendar:before {
	content: "\e915";
}

.icon-bike:before {
	content: "\e917";
}

.icon-mug:before {
	content: "\e918";
}

.icon-ruler:before {
	content: "\e919";
}

.icon-lightbulb:before {
	content: "\e91a";
}

.icon-recycle:before {
	content: "\e91b";
}

.icon-sign:before {
	content: "\e91c";
}

.icon-sing:before {
	content: "\e91d";
}

.icon-heartbeat:before {
	content: "\e91e";
}

.icon-agreement:before {
	content: "\e91f";
}

.icon-baby:before {
	content: "\e920";
}

.icon-plaster:before {
	content: "\e921";
}

.icon-stethoscope:before {
	content: "\e922";
}

.icon-target:before {
	content: "\e923";
}

.icon-dummy:before {
	content: "\e924";
}

.icon-family:before {
	content: "\e925";
}

.icon-ball:before {
	content: "\e926";
}

.icon-fence:before {
	content: "\e927";
}

.icon-farm:before {
	content: "\e928";
}

.icon-flower:before {
	content: "\e929";
}

.icon-apple:before {
	content: "\e92a";
}

.icon-wheat:before {
	content: "\e92b";
}

.icon-basket:before {
	content: "\e92c";
}

.icon-tap:before {
	content: "\e92d";
}

.icon-bonfire:before {
	content: "\e92e";
}

.icon-swimming:before {
	content: "\e92f";
}

.icon-tree:before {
	content: "\e930";
}

.icon-weather:before {
	content: "\e931";
}

.icon-worldwide:before {
	content: "\e932";
}

.icon-storm:before {
	content: "\e933";
}

.icon-kite:before {
	content: "\e934";
}

.icon-windmill:before {
	content: "\e935";
}

.icon-toy:before {
	content: "\e936";
}

.icon-arrow:before {
	content: "\e938";
}

.icon-search:before {
	content: "\e939";
}

.icon-icon-document:before {
	content: "\e914";
}
